<template>
	<div class="page">
		<Tables ref="xTable" :tableName="tableName" :tableData="List" :totalPage="totalPage" :isLoading="isLoading"
			:searchFormData="searchFormData" :showRefresh="true" :showSearch="true" :showSeq="false"
			:showAdd="$hasAccess('market.chargecoupon/add')" addName="新增" @getList="getList" @clearSearch="clearSearch"
			@addItemInTable="addOne">

			<!--搜索自定义内容-->
			<el-form-item slot="search-item">
				<el-input v-model="searchFormData.name" size="small" clearable placeholder="按名称查询">
				</el-input>
			</el-form-item>
			<el-form-item slot="search-item">
				<el-button size="small" plain @click="detail()" v-if="$hasAccess('market.chargecoupon/record')">返券记录</el-button>
			</el-form-item>
			<!--表格内容-->
			<vxe-table-column slot="table-item" field="id" title="活动ID" align="center" min-width="100px" />
			<vxe-table-column slot="table-item" field="name" title="名称" align="center" min-width="100px" />
			<vxe-table-column slot="table-item" field="platform" title="生效平台" align="center" min-width="30px">
				<template v-slot="{ row }">
					<span>{{$platform[row.platform]}}</span>
				</template>
			</vxe-table-column>
			<vxe-table-column slot="table-item" field="citys" title="区域" align="center" min-width="50px">
				<template v-slot="{ row }">
					{{row.citys.indexOf('10')!=-1?'全部':row.citys.join(',')}}
				</template>
			</vxe-table-column>
			<vxe-table-column slot="table-item" field="user_type" title="用户类型" align="center" min-width="50px">
				<template v-slot="{ row }">
					<span>{{$userType[row.user_type]||'全部'}}</span>
				</template>
			</vxe-table-column>
			<vxe-table-column slot="table-item" field="electric" title="详细配置" align="center" min-width="50px">
				<template v-slot="{ row }">
					<template v-if="row.cfg.tp=='1'">
						<span>时长配置</span>
						<div v-for="(item,idx) in row.cfg.time" v-bind:key="idx">满{{item.value}}分钟
							<div v-for="(itm,cv) in item.list" v-bind:key="cv">获得{{itm.num}}个{{itm.id}}</div>
						</div>
					</template>
					<template v-else>
						<span>度数配置</span>
						<div v-for="(item,idx) in row.cfg.electric" v-bind:key="idx">满{{item.value}}度
							<div v-for="(itm,cv) in item.list" v-bind:key="cv">获得{{itm.num}}个{{itm.id}}</div>
						</div>
					</template>
				</template>
			</vxe-table-column>
			<vxe-table-column slot="table-item" field="day_num" title="条件" align="center" min-width="80px">
				<template v-slot="{row}">
					{{row.day_num>0?row.day_num+"次/日":"非单日"}}
				</template>
			</vxe-table-column>
			<vxe-table-column slot="table-item" field="btime" title="开始时间" align="center" min-width="80px" />
			<vxe-table-column slot="table-item" field="etime" title="结束时间" align="center" min-width="80px" />
			<vxe-table-column slot="table-item" field="status" title="状态" align="center" min-width="50px">
				<template v-slot="{ row }">
					<span>{{row.status==1?'有效':'无效'}}</span>
				</template>
			</vxe-table-column>
			<vxe-table-column slot="table-item" title="操作" align="center" width="220px">
				<template v-slot="{ row }">
					<el-button size="small" plain @click="viewdetail(row)">详情</el-button>
					<el-button size="small" plain @click="editOne(row)" v-if="$hasAccess('market.chargecoupon/edit')">编辑
					</el-button>
					<el-button size="small" plain @click="deleteOne(row)">删除</el-button>
				</template>
			</vxe-table-column>
		</Tables>

		<!-- 新增/编辑 -->
		<el-dialog :title="(editDialogFormData.id>0?'编辑':'新增')+'充电返券'" :visible.sync="editDialogShow" :close-on-click-modal="false"
			:close-on-press-escape="false" width="700px">
			<el-form :model="editDialogFormData" :rules="rules" ref="editForm" label-width="100px" class="form">
				<el-form-item label="名称" prop="name">
					<el-input v-model="editDialogFormData.name" style="width: 80%;"></el-input>
				</el-form-item>
				<el-form-item label="生效平台" prop="platform">
					<el-select v-model="editDialogFormData.platform" placeholder="请选择" style="width:100%">
						<el-option v-for="(item,k) in $platform" :key="k" :label="item" :value="parseInt(k)"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="用户类型" prop="user_type">
					<el-radio-group v-model="editDialogFormData.user_type">
						<el-radio label="0">全部</el-radio>
						<el-radio v-for="(item,k) of $userType" :key="k" :label="k">{{item}}</el-radio>
					</el-radio-group>
				</el-form-item>
				<el-form-item label="区域" prop="citys">
					<el-select v-model="editDialogFormData.citys" placeholder="请选择" multiple style="width:100%" @change="changeCity">
						<el-option label="全部" value="10"></el-option>
						<el-option v-for="(item,k) in $userArea" :key="k" :label="item" :value="item"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="场站" prop="group_ids">
					<el-select v-model="editDialogFormData.group_ids" placeholder="请选择场站" multiple style="width:100%">
						<el-option v-for="(item,k) in groupList" :key="k" :label="item.group_name" :value="item.group_id"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="条件限制" prop="day_num">
					<el-input v-model="editDialogFormData.day_num" style="width: 50%;"></el-input> 次/日 <span style="color: red;">0活动区间累计</span>
				</el-form-item>	
				<el-form-item label="是否手动报名" prop="enter">
					<el-switch v-model="editDialogFormData.cfg.enter" active-value="1" inactive-value="0" />
				</el-form-item>
				<el-form-item label="">
					<el-switch v-model="editDialogFormData.cfg.tp" active-text="按时长" inactive-text="按电量" active-value="1" inactive-value="0" />
				</el-form-item>
				<el-form-item v-if="editDialogFormData.cfg.tp == '1'" label="时长配置" prop="cfg">
					<el-button class="add-button" icon="el-icon-plus" type="primary" size="small" @click="addCfg">增加档位</el-button><span style="color: red;">档位必须从大到小</span><br/>
					<div v-for="(item,idx) in editDialogFormData.cfg.time" v-bind:key="idx">
						大于等于<el-input-number type="number" :min="1" :max="600" :step="1" :precision="0"
							v-model="editDialogFormData.cfg.time[idx].value" style="width: 108px;" class="input" size="small" placeholder="分钟">
						</el-input-number>分
						<el-button class="add-button" icon="el-icon-plus" type="primary" size="small" @click="addCoupon(idx)">增加券</el-button>
						<el-button class="del-button" size="small" type="danger" icon="el-icon-delete" @click="onItemDel(idx)">删除</el-button>
						<div v-for="(itm,idx2) in item.list" v-bind:key="idx2">
							获得
							<el-select v-model="editDialogFormData.cfg.time[idx].list[idx2].type" style="width: 110px;" size="small" @change="itemTypeChange(idx,idx2)">
								<el-option label="优惠券" :value="0"></el-option>
								<el-option label="电卡" :value="1"></el-option>
							</el-select>
							<el-select v-if="editDialogFormData.cfg.time[idx].list[idx2].type==0" v-model="editDialogFormData.cfg.time[idx].list[idx2].id" style="width: 130px;"  class="select" size="small" placeholder="请选择优惠券" clearable>
								<el-option label="VIP" value="VIP"></el-option>
								<el-option v-for="item in couponList" :key="item.coupon_id" :label="item.coupon_name" :value="item.coupon_id">
								</el-option>
							</el-select> 
							<el-select v-if="editDialogFormData.cfg.time[idx].list[idx2].type==1" v-model="editDialogFormData.cfg.time[idx].list[idx2].id" style="width: 130px;" clearable  class="select" size="small" placeholder="请选择奖品">
								<el-option v-for="item in cardList" :key="item.card_id" :label="item.card_name" :value="item.card_id">
								</el-option>
							</el-select>
							数量<el-input-number type="number" :min="1" :max="100" :step="1" :precision="0"
								v-model="editDialogFormData.cfg.time[idx].list[idx2].num" style="width: 108px;" class="input" size="small" placeholder="分钟">
							</el-input-number>
							<el-button class="del-button" size="small" type="danger" icon="el-icon-delete" @click="delCoupon(idx,idx2)"></el-button> 	
						</div>					
					</div>
				</el-form-item>
				<el-form-item v-else label="电量配置" prop="cfg">
					<el-button class="add-button" icon="el-icon-plus" type="primary" size="small" @click="addCfg">增加档位</el-button><span style="color: red;">档位必须从大到小</span><br/>
					<div v-for="(item,idx) in editDialogFormData.cfg.electric" v-bind:key="idx">
						大于等于<el-input-number type="number" :min="1" :max="600" :step="1" :precision="0"
							v-model="editDialogFormData.cfg.electric[idx].value" style="width: 108px;" class="input" size="small" placeholder="分钟">
						</el-input-number>度
						<el-button class="add-button" icon="el-icon-plus" type="primary" size="small" @click="addCoupon(idx)">增加券</el-button>
						<el-button class="del-button" size="small" type="danger" icon="el-icon-delete" @click="onItemDel(idx)">删除</el-button> 
						<div v-for="(itm,idx2) in item.list" v-bind:key="idx2">
							获得
							<el-select v-model="editDialogFormData.cfg.electric[idx].list[idx2].type" style="width: 110px;" size="small" @change="itemTypeChange(idx,idx2)">
								<el-option label="优惠券" :value="0"></el-option>
								<el-option label="电卡" :value="1"></el-option>
							</el-select>
							<el-select v-if="editDialogFormData.cfg.electric[idx].list[idx2].type==0" v-model="editDialogFormData.cfg.electric[idx].list[idx2].id" style="width: 130px;"  class="select" size="small" placeholder="请选择优惠券">
								<el-option label="VIP" value="VIP"></el-option>
								<el-option v-for="item in couponList" :key="item.coupon_id" :label="item.coupon_name" :value="item.coupon_id">
								</el-option>
							</el-select> 
							<el-select v-if="editDialogFormData.cfg.electric[idx].list[idx2].type==1" v-model="editDialogFormData.cfg.electric[idx].list[idx2].id" style="width: 130px;" clearable  class="select" size="small" placeholder="请选择奖品">
								<el-option v-for="item in cardList" :key="item.card_id" :label="item.card_name" :value="item.card_id">
								</el-option>
							</el-select>
							数量
							<el-input-number type="number" :min="1" :max="100" :step="1" :precision="0"
								v-model="editDialogFormData.cfg.electric[idx].list[idx2].num" style="width: 108px;" class="input" size="small" placeholder="数量">
							</el-input-number>
							<el-button class="del-button" size="small" type="danger" icon="el-icon-delete" @click="delCoupon(idx,idx2)"></el-button> 							
						</div>
					</div>
				</el-form-item>
				<el-form-item label="开始时间" prop="btime">
					<el-date-picker class="from-item-width" unlink-panels v-model="editDialogFormData.btime"
						type="date" value-format="yyyy-MM-dd" placeholder="开始时间"></el-date-picker>
				</el-form-item>
				<el-form-item label="结束时间" prop="etime">
					<el-date-picker class="from-item-width" unlink-panels v-model="editDialogFormData.etime"
						type="date" value-format="yyyy-MM-dd" placeholder="结束时间"></el-date-picker>
				</el-form-item>
				<el-form-item label="是否有效" prop="status">
					<el-switch v-model="editDialogFormData.status" active-value="1" inactive-value="0" clearable />
				</el-form-item>
			</el-form>
			<div slot="footer" class="dialog-footer">
				<el-button type="primary" size="small" plain icon="el-icon-check" @click="saveEdit()">保 存
				</el-button>
				<el-button size="small" plain icon="el-icon-close" @click="closeDialog">取 消
				</el-button>
			</div>
		</el-dialog>
	</div>
</template>

<script>
	import Tables from '@/components/tables'
	export default {
		name: 'market-charge-list',
		components: {
			Tables
		},
		data() {
			return {
				// 表格信息
				tableName: '充电返券',
				isLoading: false,
				totalPage: 0,
				searchFormData: {},
				List: [],
				couponList: [], //优惠券列表
				groupList:[], //场站列表
				cardList:[], //电卡列表
				selecttime: {},
				city_type:{
					10:'全部',
					20:'苏州',
					30:'无锡'
				},
				user_type:{
					0:'全部',
					1:'非VIP用户',
					2:'VIP用户'
				},
				rules: {
					name: [{
						required: true,
						message: '请输入名称',
						trigger: 'change'
					}],
					platform: [{
						required: true,
						message: '请选择生效平台',
						trigger: 'change'
					}],
					user_type: [{
						required: true,
						message: '请选择用户类型',
						trigger: 'change'
					}],
					citys: [{
						required: true,
						message: '请选择区域',
						trigger: 'change'
					}],
					day_num: [{
						required: true,
						message: '请输入条件限制',
						trigger: 'change'
					}],
					btime: [{
						required: true,
						message: '请输入活动开始时间',
						trigger: 'change'
					}],
					etime: [{
						required: true,
						message: '请输入活动结束时间',
						trigger: 'change'
					}],
					cfg: {
						required: true,
						message: '请输入正确的配置',
						trigger: 'change',
						validator: (rule, value, callback)=>{
							if(value.tp == '1'){ //时长配置
								if(value.time.length < 1) return callback(new Error('必须有配置信息'));
								let ma = -1;
								for(let v of value.time){
									if(v.id == "") return callback(new Error('必须选中优惠券'));
									if(ma > -1){
										if(ma < v.value) return callback(new Error('档位必须从大到小'));
									}
									ma = v.value;
								}
							}else{
								if(value.electric.length < 1){
									//console.log("value.electric.length", value.electric.length);
									callback(new Error('必须有配置信息'));
									return false;
								} 
								let ma = -1;
								for(let v of value.electric){
									if(v.id == ""){
										//console.log("v[1]", v[1]);
										callback(new Error('必须选中优惠券'));
										return false;
									} 
									if(ma > -1){
										if(ma < v.value){
											//console.log("ma < v[0]", ma, v[0]);
											callback(new Error('档位必须从大到小'));
											return false;
										} 
									}
									ma = v.value;
								}
							}
							callback();
						}
					}
				},
				// 编辑
				editDialogShow: false,
				editDialogFormData: {cfg:{}},
			}
		},
		computed: {
		},
		mounted() {
			this.getList();
			//获取优惠券列表
			const params = {
				token: this.$store.state.user.token,
				page: 1,
				size: 100,
			};
			this.$api.Market.GetCouponList(params, {}).then((res)=>{
				for (let d of res.data) {
					if (d.max_money != null) {
						d.coupon_name = d.coupon_name + '(' + d.max_money + '元)';
					}
				}
				this.couponList = res.data;				
			});
			this.$api.Market.GetCardList(params, {}).then((res)=>{
				this.cardList = res.data;				
			});
		},
		activated() {},
		methods: {
			// 获取标签内容
			async getList() {
				let params = {
					token: this.$store.state.user.token,
					page: 1,
					size: 100
				}
				params = Object.assign(params, this.searchFormData);
				const res = await this.$api.Market.ChargeCoupon.list(params, {})
				for(let v of res.data){
					if(v.cfg.electric){
						for(let r=0;r<v.cfg.electric.length;r++){
							if(!v.cfg.electric[r].value){
								let obj = {value:v.cfg.electric[r][0],list:[]};
								for(let i=1;i<v.cfg.electric[r].length;i+=2){
									obj.list.push({type:0,id:v.cfg.electric[r][i],num:v.cfg.electric[r][i+1]});
								}
								v.cfg.electric[r] = obj;
							}
						}
					}
					if(v.cfg.time){
						for(let r=0;r<v.cfg.time.length;r++){
							if(!v.cfg.time[r].value){
								let obj = {value:v.cfg.time[r][0],list:[]};
								for(let i=1;i<v.cfg.time[r].length;i+=2){
									obj.list.push({type:0,id:v.cfg.time[r][i],num:v.cfg.time[r][i+1]});
								}
								v.cfg.time[r] = obj;
							}
						}
					}
				}
				//console.log(res.data);
				this.List = res.data;
			},
			// 重置搜索
			clearSearch() {
				this.searchFormData = {}
			},
			// 重置form
			resetFormData() {
				this.editDialogFormData = {city:0,electric:0,coupon_id:0,coupon_num:0,cfg:{tp:'0',enter:'0',electric:[],time:[]}}
				if (this.$refs.editForm) {
					this.$refs.editForm.resetFields()
				}
			},
			// 新增
			addOne() {
				this.resetFormData()
				this.editDialogShow = true
			},
			// 编辑
			async editOne(row) {
				this.resetFormData();
				//row.city = row.city.toString();
				row.user_type = row.user_type.toString();
				row.status = row.status.toString();
				if(!row.cfg){
					row.cfg = {tp:'0',electric:[],time:[]};
				}
				if(!row.group_ids) row.group_ids = [];
				this.editDialogFormData = Object.assign({}, row);
				delete this.editDialogFormData._XID;
				this.editDialogShow = true;
				this.getGroup();
			},
			// 删除
			deleteOne(row) {
				this.$confirm('确定要删除该记录吗', '提示', {
					cancelButtonClass: 'btn-custom-cancel',
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(async () => {
					const params = {
						token: this.$store.state.user.token,
						id: row.id
					}
					await this.$api.Market.ChargeCoupon.del(params)
					this.$notify({
						title: '成功',
						message: '删除成功',
						type: 'success'
					})
					this.$refs.xTable.refreshTable()
				})
			},
			// 关闭弹窗
			closeDialog() {
				this.editDialogShow = false;
				this.$refs.xTable.refreshTable();
			},
			// 编辑保存
			saveEdit() {
				this.$refs.editForm.validate(async valid => {
					//console.log('valid', valid)
					if (valid) {
						this.editDialogFormData.cfg = JSON.stringify(this.editDialogFormData.cfg);
						const params = {
							token: this.$store.state.user.token,
							form: JSON.stringify(this.editDialogFormData)
						}
						if(this.editDialogFormData.id > 0){
							await this.$api.Market.ChargeCoupon.edit(params);
						}else{
							await this.$api.Market.ChargeCoupon.add(params);
						}
						this.$notify({
							title: '成功',
							message: '操作成功',
							type: 'success'
						})
						this.closeDialog();
					}
				})
			},
			changeCity(e){ //修改城市
				if(e.indexOf('10') != -1) this.editDialogFormData.group_ids = [];
				this.getGroup();
			},
			getGroup(){ //获取场站
				const params = {
					token: this.$store.state.user.token,
					page: 1,
					size: 100,
					city: this.editDialogFormData.citys
				}
				this.$api.Device.GetGroupMinList(params).then((res)=>{
					this.groupList = res;
					let gids = [];
					for(let gid of this.editDialogFormData.group_ids){
						for(let gv of this.groupList){
							if(gv.group_id == gid){
								gids.push(gid);
							}
						}
					}
					this.editDialogFormData.group_ids = gids;
				});
			},
			addCfg(){ //添加配置
				if(this.editDialogFormData.cfg.tp == '0'){
					this.editDialogFormData.cfg.electric.push({value:0,list:[{type:0,id:'',num:1}]});
				}else{
					this.editDialogFormData.cfg.time.push({value:0,list:[{type:0,id:'',num:1}]});
				}
			},
			addCoupon(idx){ //添加优惠券
				if(this.editDialogFormData.cfg.tp == '0'){
					this.editDialogFormData.cfg.electric[idx].list.push({type:0,id:'',num:1});
				}else{
					this.editDialogFormData.cfg.time[idx].list.push({type:0,id:'',num:1});
				}
			},
			delCoupon(idx,ci){ //删除优惠券
				if(this.editDialogFormData.cfg.tp == '0'){
					this.editDialogFormData.cfg.electric[idx].list.splice(ci, 1);
				}else{
					this.editDialogFormData.cfg.time[idx].list.splice(ci, 1);
				}
			},
			itemTypeChange(idx, ci){ //奖励类型修改
				if(this.editDialogFormData.cfg.tp == '0'){
					this.editDialogFormData.cfg.electric[idx].list[ci].id = '';
				}else{
					this.editDialogFormData.cfg.time[idx].list[ci].id = '';
				}
			},
			onItemDel(idx){ //删除配置
				if(this.editDialogFormData.cfg.tp == '0'){
					this.editDialogFormData.cfg.electric.splice(idx, 1);
				}else{
					this.editDialogFormData.cfg.time.splice(idx, 1);
				}
			},
			// 返券记录
			detail() {
				this.$router.push({
					name: "market-charge-record",
					query: {},
				});
			},
			// 返券记录
			viewdetail(row) {
				this.$router.push({
					name: "market-charge-users",
					query: {id:row.id},
				});
			},
		}
	}
</script>

<style lang="scss" scoped>
</style>
